:root {
	--main-side-nav-primary: #492797;
	--main-menu-secondary: #e4dcf3;
	--main-side-nav-hover: #684ca9;
	--main-side-nav-text: #ffffff;
}

* {
	font-family: 'HK Grotesk Regular';
}
.menulist-item {
	overflow-x: hidden;
	overflow-y: auto;
	font-family: 'HK Grotesk Regular' !important;
	font-size: 16px !important;
}

.menulist-item .MuiTypography-body1 {
	font-weight: 600 !important;
	font-family: 'HK Grotesk Regular' !important;
}

.menulist-item ::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.menulist-item ::-webkit-scrollbar-track {
	/* box-shadow: inset 0 0 5px grey;  */
	background: #b4b4b4;
	border-radius: 4px;
}

/* Handle */
.menulist-item ::-webkit-scrollbar-thumb {
	background: var(--main-side-nav-primary);
	border-radius: 4px;
}

/* Handle on hover */
.menulist-item ::-webkit-scrollbar-thumb:hover {
	background: var(--main-side-nav-primary);
}
#my-popper-item-drawer {
	position: absolute;
	width: 94%;
	top: -2px !important;
	left: 2px !important;
	transform: translate3d(-4px, 502px, 0px);
	height: 160px;
	overflow: auto;
	z-index: 1200;
	width: 225px;
}
#my-popper-item {
	position: absolute;
	width: 94%;
	top: -2px !important;
	left: 8px !important;
	transform: translate3d(-4px, 502px, 0px);
	height: 160px;
	overflow: auto;
	z-index: 1200;
	width: 225px;
}
.main-navlink {
	width: 20%;
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	border: 1px solid var(--main-side-nav-primary);
	border-radius: 16px;
	overflow: hidden;
}
.place-order {
	width: auto;
	list-style-type: none;
	padding: 0px;
	margin: 0px;
	border: 1px solid var(--main-side-nav-primary);
	margin-top: 20px;
	display: inline-block;
}

.link-customization {
	color: inherit;
	text-decoration: unset;
	cursor: pointer;
}

.link-customization-child {
	background-color: var(--main-side-nav-primary);
	text-decoration: unset;
	cursor: pointer;
}
.link-customization-child-active {
	background-color: var(--main-side-nav-hover);
	text-decoration: unset;
	cursor: pointer;
}

.menulist-item .MuiListItem-root:hover {
	background: var(--main-side-nav-hover);
	border-radius: 4px;
}

.menulist-item .MuiListItem-root.Mui-selected {
	background: var(--main-menu-secondary);
	border-radius: 4px;
	color: var(--main-side-nav-primary);
}

.menulist-item .MuiListItem-root.Mui-selected:hover {
	background: var(--main-menu-secondary);
	border-radius: 4px;
	color: var(--main-side-nav-primary);
}

.submenu-menulist-item .MuiListItem-root.Mui-selected {
	background: rgba(228, 220, 243, 0.5);
	border-radius: 4px;
	color: var(--main-menu-selected);
}

.submenu-menulist-item .MuiListItem-root.Mui-selected:hover {
	background: var(--main-side-nav-hover);
	border-radius: 4px;
	color: var(--main-menu-selected);
}

.list-item-icon-alignment {
	min-width: 36px !important;
}

.list-item-icon-alignment-close-drawer {
	min-width: 56px !important;
}

.main-navlink li {
	width: 100%;
	border-bottom: 1px solid var(--main-side-nav-primary);
}
.main-navlink li:last-child {
	border: none;
}
.main-navlink li a,
.place-order a {
	padding: 10px;
	width: 100%;
	display: block;
	margin: 0px;
	color: var(--main-side-nav-primary);
	font-family: HankenGroteskBold;
	text-align: center;
	text-decoration: none;
	font-size: 14px;
}
.main-navlink li a.active,
.place-order a.active {
	background: var(--main-side-nav-primary);
	color: #fff;
}

.sidenav {
	height: 100%;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	background-color: var(--main-side-nav-primary);
	overflow-x: hidden;
}
.left-menu {
	margin-top: 40px;
	float: 'right';
}
.menu-items {
	padding-top: 10px;
	padding-bottom: 10px;
}

.single-item {
	color: white;
	font-size: 12px;
	font-weight: 600;
}

.single-item-selected {
	color: var(--main-side-nav-primary);
	text-decoration: none !important;
}

.heading-wrap {
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	width: 85%;
	margin-left: 15%;
	display: flex;
	align-items: center;
	height: 40px;
	padding-left: 20px;
	font-family: HK Grotesk Regular;
	margin-top: 20px;
}

.heading-wrap-selected {
	background-color: #fff;
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	width: 85%;
	margin-left: 15%;
	display: flex;
	align-items: center;
	height: 40px;
	padding-left: 20px;
	font-family: HK Grotesk Bold;
	margin-top: 20px;
}

.logo-wrap {
	border-radius: 20px;
	width: 200px;
	align-items: center;
	justify-content: center;
	/* height: 35px; */
	margin-top: 100px;
	padding-bottom: 10px;
}

.logo {
	margin-left: 52px;
	margin-top: 52px;
}
.btn-place-order {
	border-color: #fff !important;
}

.signOut:hover {
	color: red !important;
}

.icon_style {
	fill: var(--main-side-nav-primary) !important;
}

.icon_style path {
	fill: var(--main-side-nav-primary) !important;
}

.icon_active_style {
	fill: var(--main-side-nav-text) !important;
}

.icon_active_style path {
	fill: var(--main-side-nav-text) !important;
}

.icons_wrap {
	background-color: transparent;
	height: 30px;
	width: 30px;
	padding: 3px;
	border-radius: 50%;
	cursor: pointer;
}
.icons_wrap :hover {
	height: 32px;
	width: 32px;
	padding: 2px;
	border-radius: 50%;
	background-color: var(--main-side-nav-hover);
	cursor: pointer;
}
.icons_wrap_active {
	background-color: var(--main-menu-secondary);
	height: 30px;
	width: 30px;
	padding: 3px;
	border-radius: 50%;
}

.business_details_wrap {
	padding: 8px 20px;
	background-color: var(--main-side-nav-hover);
	margin-top: 10px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
}
.expanded_drawer_arrow {
	color: var(--main-side-nav-primary);
	z-index: 1201;
	position: absolute;
	left: 224px;
	top: 50%;
	padding: 6px;
	background: var(--main-menu-secondary);
	border-radius: 50%;
	height: 32px;
	width: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}
.search_icon_navbar {
	cursor: pointer;
}
.search_icon_navbar path {
	fill: var(--main-side-nav-text) !important;
}
.divider_line {
	background-color: var(--main-side-nav-text) !important;
}
.logout_wrap_active {
	background-color: var(--main-menu-secondary);
	cursor: pointer;
}

.logout_wrap_inactive {
	background-color: 'unset';
	cursor: pointer;
}
