.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: #fff;
}

* {
	font-size: 14px;
	margin: 0;
	padding: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	font-family: 'HK Grotesk Regular';
}

.container {
	padding: 32px 32px 0px 32px;
}

#input_search_filter::placeholder {
	color: #cabde9;
}

.react-datepicker-popper {
	z-index: 3 !important;
}

.sd {
	pointer-events: none;
}

@font-face {
	font-family: 'HK Grotesk Bold';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Bold.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk SemiBold';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-SemiBold.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk Medium';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Medium.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk Regular';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Regular.otf) format('opentype');
}
@font-face {
	font-family: 'HK Grotesk Light';
	src: local('HK Grotesk'), url(./assets/fonts/HKGrotesk-Light.otf) format('opentype');
}

.green-dot {
	height: 10px;
	width: 10px;
	background-color: green;
	border-radius: 5px;
}

.red-dot {
	height: 10px;
	width: 10px;
	background-color: #eb5757;
	border-radius: 5px;
}

.orange-dot {
	height: 10px;
	width: 10px;
	background-color: orange;
	border-radius: 5px;
}

.purple-dot {
	height: 10px;
	width: 10px;
	background-color: #492897;
	border-radius: 5px;
}

.primary-dot {
	height: 7px;
	width: 7px;
	background-color: #492897;
	border-radius: 50%;
	margin-top: 2px;
	margin-bottom: 2px;
}

.box-shadow {
	box-shadow: 2px 5px 16px 0px rgba(196, 196, 196, 0.3);
}

p,
span {
	word-break: break-word;
}

.slick-dots-custom {
	bottom: -30px !important;
	width: 70px !important;
	display: flex;
	justify-content: space-between;
	left: 65px;
}

.slick-dots-custom-biz {
	bottom: -10px !important;
	width: 100% !important;
	left: 0px;
}

.slick-dots-custom > li {
	width: auto !important;
	height: auto !important;
}

.slick-dots-custom > .slick-active > div {
	background-color: #492897 !important;
	width: 24px !important;
	height: 5px !important;
}

.flex_between_center {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.page_back_btn {
	transform: rotate(270deg);
	margin-right: 4px;
	cursor: pointer;
}

.header_separator {
	height: 20px;
	width: 1px;
	background-color: #666666;
	margin-left: 8px;
	margin-right: 8px;
}

.header_active_title {
	font-size: 20px;
	color: #1e1e1e;
	line-height: 36px;
	text-underline-offset: 5px;
	text-decoration: underline;
	text-decoration-color: #e4dcf3;
	text-decoration-thickness: 2px;
}

input[type='date']::-webkit-calendar-picker-indicator {
	background: transparent;
	bottom: 0;
	color: transparent;
	cursor: pointer;
	height: auto;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: auto;
}

.leaflet-left {
	right: 10px;
	left: auto !important;
}

.leaflet-left {
	bottom: 50px;
	top: auto !important;
}
