.leaflet-container {
    height: 100%;
    width: 100%;
}

.leaflet-top {
    top: 85%;
}

.leaflet-left {
    left: 95%;
}

.leaflet-div-icon{
	background-color: transparent;
	border: none;
}

.leaflet-control-container .leaflet-routing-container-hide {
    display: none;
}

.rider_popup .leaflet-popup-content-wrapper {
    border-radius: 8px;
    border:1px solid #CABDE9;
    filter: drop-shadow(0px 0px 16px rgba(153, 128, 219, 0.20))

  }